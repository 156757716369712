#lang-selector {
  #chevron {
    transition: transform 0.2s ease-out;
  }
  &.open {
    #chevron {
      transform: rotate(180deg);
    }
  }
}
