.mapboxgl-control-container {
  .mapboxgl-ctrl-bottom-right {
    .mapboxgl-ctrl {
      @apply bg-transparent shadow-none mr-9 mb-7 flex flex-col gap-1;
    }

    .mapboxgl-ctrl-group {
      .mapboxgl-ctrl-zoom-in {
        @apply flex justify-center items-center;
        // &::after {
        //   content: "+";
        //   text-align: center;
        //   width: 100%;
        // }
      }

      .mapboxgl-ctrl-zoom-out {
        @apply flex justify-center items-center;
        // &::after {
        //   @apply text-center text-2xl font-extralight;
        //   content: "-";
        // }
      }

      button {
        @apply bg-white relative rounded-full shadow-button w-10 h-10;

        span {
          @apply font-body text-2xl;
          //   background: none;
          //   display: none;;
        }
      }
    }
  }
  .mapboxgl-ctrl-bottom-left {
    @apply flex flex-col-reverse;
  }
}

.map-container {
  .mapboxgl-popup {
    @apply absolute top-[15%] left-[55%] max-h-[430px] transform-none;
    .mapboxgl-popup-tip {
      @apply hidden;
    }
  }
}
