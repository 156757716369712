.hero-municipalities #arrow-down-twirl {
  top: 5rem;
  &.animate {
    animation-name: hero-municipalities-arrow-bump;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
  }
}

@keyframes hero-municipalities-arrow-bump {
  0% {
    top: 5rem;
  }
  50% {
    top: 4.75rem;
  }
  100% {
    top: 5rem;
  }
}
