#nav {
  .hamburger-menu {
    & > div {
      transition: all .7s ease;
    }
  }

  #nav-menu {
    transition: left 0.4s ease-out;
  }
}
