#page {
  &.tools {
    .tools-list {
      #image {
        background-repeat: no-repeat !important;
        background-color: transparent;
        background-size: cover !important;
        background-position: center !important;
      }
    }
  }
}
