#dropdown-hover {
  &.active {
    @apply lg:bg-gray-200;
  }
  &:hover {
    & > ul#dropdown-item-container {
      @apply lg:absolute lg:flex lg:flex-col;
      top: 3.75rem;
    }
  }

  #label {
    svg {
      transition: transform 0.5s ease;
    }
  }

  &.expand {
    #label {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  & > ul#dropdown-item-container {
    @apply flex flex-col lg:hidden;
    transition: all 0.4s ease-out;
    top: 3.75rem;

    li {
      @apply px-6;
    }
  }
}
