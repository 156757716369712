#partner-list {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    background-color: white;

    width: 110vw;
    transform: translateX(-50%) rotate(-2deg);
    @apply shadow-card
  }
}