@keyframes spring {
  0% {
    width: 0;
    margin-left: 0;
    margin-right: 0;
  }

  100% {
    margin-left: 5px;
    margin-right: 5px;
    width: 100%;
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw2 {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.draw {
  overflow: hidden;
  width: 0;
  height: 42px;
  &.animate {
    animation-name: draw2;
    animation-duration: 1.5s;
    // animation-delay: 2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }

  .pt {
    max-width: 180px;
  }

  .en {
    max-width: 90px;
  }
}

.draw {
  .pt {
    img {
      min-width: 183px;
    }
  }

  .en {
    min-width: 93px;
  }
}

.squiggle {
  stroke-dasharray: 450;
  stroke-dashoffset: 450;
  &.animate {
    animation: draw 2s linear forwards;
  }
}

.image-grow {
  margin-top: auto;
  margin-bottom: auto;
  height: 55px;
  width: 0;
  &.animate {
    animation-name: spring;
    animation-duration: 1.5s;
    // animation-delay: 0.3s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.535, 1.09, 0.6, -0.6);
  }
}

.hero-star {
  &.animate {
    animation-name: rotate-hero-star;
    animation-duration: 50s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

@keyframes rotate-hero-star {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hero #arrow-down-twirl {
  top: -5rem;
  &.animate {
    animation: hero-arrow-bump 500ms ease-in-out infinite;
  }
}

@keyframes hero-arrow-bump {
  0% {
    top: -5rem;
  }
  50% {
    top: -4.75rem;
  }
  100% {
    top: -5rem;
  }
}

.hero #arrow-up-twirl {
  bottom: -5rem;
  &.animate {
    animation: hero-arrow-up-bump 500ms ease-in-out infinite;
  }
}

@keyframes hero-arrow-up-bump {
  0% {
    bottom: -5rem;
  }
  50% {
    bottom: -4.75rem;
  }
  100% {
    bottom: -5rem;
  }
}
