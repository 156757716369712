@keyframes slide-gesture {
  0% {
    height: 25%;
    width: 25%;
    opacity: 0.2;
  }

  10% {
    opacity: .3
  }

  25% {
    opacity: .7;
  }

  50% {
    height: 5%;
    width: 35%;
    opacity: 0;
    transform: translate(-250%);
  }

  100% {
    opacity: 0;
  }
}

@keyframes hand-move {
  0% {
    opacity: 0;
    transform: rotate(30deg);
  }

  5% {
    opacity: 0.2;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: translate(-60%, -10%) rotate(-30deg) scale(0.9);
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: rotate(30deg);
  }
}

#slide-gesture {
  width: 100px;
  height: 100px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 100%;
  left: 55%;
  opacity: 1;
  transition: 300ms;
  pointer-events: none;
  z-index: 1000;

  #slide-feedback {
    height: 48px;
    width: 100px;
    position: absolute;
    top: -5px;
    left: 50px;
    border-radius: 100px;
    z-index: -1;
    animation: slide-gesture 3s infinite;
  }

  #tap-gesture {
    transform: rotate(30deg);
    animation: hand-move 3s infinite;
    z-index: 2;
  }
}
