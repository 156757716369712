.contact-us {
  .landing,
  .schools,
  .municipalities,
  .team,
  .history {
    display: flex;
  }

  #contact-form {
    &.politician {
      .politician {
        @apply block;
      }

      .teacher {
        @apply hidden;
      }
    }

    &.teacher {
      .teacher {
        @apply block;
      }

      .politician {
        @apply hidden;
      }
    }

    &.other {
      .politician,
      .teacher {
        @apply hidden;
      }
    }

    &.youth {
      .politician,
      .teacher {
        @apply hidden;
      }
    }
  }
}
