#faq {
  a {
    @apply text-blue-500 hover:text-blue-600;
  }

  [id^='faq-title'] {
    #flip {
      & > span {
        transition: transform 0.3s ease-out;
      }
    }

    &.active {
      @apply rounded-b-none;

      #flip {
        & > span {
          transition: transform 0.3s ease-out;
          transform: rotate(-180deg);
        }
      }
    }
  }
}
