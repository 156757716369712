.impact #impact-balloon {
    animation-name: wiggle;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
}

@keyframes wiggle {
    0% {
        transform: rotate(-5deg);
    }
    50% {
        transform: rotate(5deg);
    }
    100% {
        transform: rotate(-5deg);
    }
}