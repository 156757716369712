.card {
  &.team-card {
    margin: 0.75rem 0;
    max-height: 280px;
    transition: max-height 0.5s ease-out;
    
    .chevron-button {
      svg {
        transition: transform 0.5s ease;
      }
    }

    @media (min-width: 1024px) {
      max-height: 725px;
      margin: 0;
    }

    &.expanded {

      max-height: 725px;

      .description,
      .socials,
      .email {
        transition: opacity 0.6s ease-in-out;
        opacity: 100;
      }

      .chevron-button {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    &:not(.expanded) {

      .description,
      .socials,
      .email {
        transition: opacity 0.25s ease-in-out;
        opacity: 0;
      }

      @media (min-width: 1024px) {
        .description,
        .socials,
        .email {
          opacity: 1;
        }
      }
    }

    div {
      &.image {
        max-height: 275px;
        background-size: cover;
      }
    }
  }
}
