.card {
  &.tool-card {
    margin: 2rem 0.5rem;

    div {
      &.image {
        background-size: cover !important;
        background-position: center !important;
      }
    }
  }
}
